(function () {
    ("use strict");

    var searchRequest = null;
    var cpf = null;

    $("#agendamento [name='cpf']").mask("000.000.000-00", {
        reverse: true,
    });

    // https://stackoverflow.com/questions/1909441/how-to-delay-the-keyup-handler-until-the-user-stops-typing
    $("#agendamento [name='cpf']").on("keyup", function () {
        let that = $(this),
            value = $(this).val().replace(/[^0-9.-]/g, "");

        if (value.length == 14 && isValidCPF(value) && cpf != value) {
            if (searchRequest != null) {
                searchRequest.abort();
            }

            $("#agendamento .menssage").html('');
            searchRequest = $.ajax({
                type: "GET",
                url: "/api/person",
                data: {
                    'cpf': value
                },
                dataType: "json",
                success: function (r) {
                    if (typeof r !== "object") {

                    } else if (r.success) {
                        $("#agendamento [name='name']").val(r.data.nome);
                        $("#agendamento [name='service'], #agendamento [name='instituicao']").prop('disabled', false)
                    } else {
                        $("#agendamento .menssage").html(`
                            <div class="alert alert-danger alert-dismissible">
                                <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                                <h5><i class="icon fas fa-ban"></i> Ops!</h5>
                                ` + r.message.join('\n') + `
                            </div>
                        `);
                    }
                    let botao = that.closest(".form-group").find("button");
                    if (botao.length > 0) {
                        botao.removeClass("btn-default");
                        botao.find("i").removeClass("fa-search");
                        botao.prop('disabled', false);
                        botao.addClass("btn-danger");
                        botao.find("i").addClass("fa-trash-alt");

                    }
                    // that.closest(".input-group").append(`<div class="input-group-append remover-btn"><button type="button" class="btn btn-danger"><i class="fas fa-times-circle"></i></button></div>`);
                    // that.closest(".input-group-append").addClass("is-invalid");
                }, error: function () {
                    $("#agendamento .menssage").html(`
                        <div class="alert alert-danger alert-dismissible">
                            <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                            <h5><i class="icon fas fa-ban"></i> Ops!</h5>
                            Não consegui conversar com o servidor, caso o problema persista, entre em contato com a equipe
                        </div>
                    `);
                }
            });

            $(this).prop('readonly', true);
            // console.log($(this).parent().find("button").prop('disabled', false));
        }

        // ARMAZENA O CPF INDEPENDENTE DE ESTÁ VALIDO OU NÃO
        cpf = value;
    });

    $("#agendamento .cpf button").on("click", function () {
        $("#agendamento .menssage").html('');
        $("#agendamento [name='cpf'], #agendamento [name='name'], #agendamento [name='service'], #agendamento [name='instituicao'], #agendamento [name='data']").val('').trigger('change');
        $("#agendamento [name='cpf']").prop('readonly', false)
        $(this).removeClass("btn-danger");
        $(this).find("i").removeClass("fa-trash-alt");
        $(this).prop('disabled', true);
        $("#agendamento [name='service'], #agendamento [name='instituicao']").prop('disabled', true);
        $(this).addClass("btn-default");
        $(this).find("i").addClass("fa-search");
        cpf = null;
    });

    $("#modal-agenda-agendamento button.cancelar").on("click", function () {
        limpaTudo();
    });

    function limpaTudo() {
        cpf = null;

        let form = $("#agendamento");

        form.get(0).reset();
        form.find('select').each(function () {
            $(this).val(null).trigger('change');
        });

        let btnCpf = form.find('.cpf button');
        form.find('#cpf').prop('readonly', false);
        btnCpf.removeClass("btn-danger");
        btnCpf.find("i").removeClass("fa-trash-alt");

        btnCpf.addClass("btn-default");
        btnCpf.find("i").addClass("fa-search");
    }

    $("#agendamento .instituicao").select2({
        theme: 'bootstrap4',
        language: "pt-BR",
        width: $(this).data('width') ? $(this).data('width') : $(this).hasClass('w-100') ? '100%' : 'style',
        placeholder: $(this).data('placeholder'),
        allowClear: Boolean($(this).data('allow-clear')),
        closeOnSelect: !$(this).attr('multiple'),
        ajax: {
            url: '/api/instituicao',
            dataType: 'json',
            delay: 250,
            data: function (params) {
                return {
                    q: params.term
                };
            },
            processResults: function (data, params) {
                var arr = [];
                $.each(data.data, function (index, value) {
                    arr.push({
                        id: value.cnpj,
                        text: `${value.cnpj} - ${value.nome}`
                    });
                })

                return {
                    results: arr
                };
            },
            cache: true
        },
        minimumInputLength: 3,
    });

    $("#agendamento .service").select2({
        theme: 'bootstrap4',
        language: "pt-BR",
        width: $(this).data('width') ? $(this).data('width') : $(this).hasClass('w-100') ? '100%' : 'style',
        placeholder: $(this).data('placeholder'),
        allowClear: Boolean($(this).data('allow-clear')),
        closeOnSelect: !$(this).attr('multiple'),
        ajax: {
            url: '/api/service',
            dataType: 'json',
            delay: 250,
            data: function (params) {
                return {
                    q: params.term
                };
            },
            processResults: function (data, params) {
                var arr = [];
                $.each(data.data, function (index, value) {
                    arr.push({
                        id: value.id_public,
                        text: `${value.name}`
                    });
                })

                return {
                    results: arr
                };
            },
            cache: true
        },
    });

    $("#agendamento .responsible").select2({
        theme: 'bootstrap4',
        language: "pt-BR",
        width: $(this).data('width') ? $(this).data('width') : $(this).hasClass('w-100') ? '100%' : 'style',
        placeholder: $(this).data('placeholder'),
        allowClear: Boolean($(this).data('allow-clear')),
        closeOnSelect: !$(this).attr('multiple'),
        ajax: {
            url: '/api/responsible',
            dataType: 'json',
            delay: 250,
            data: function (params) {
                return {
                    service: $("#agendamento .service option:selected").val() ?? params.term
                };
            },
            processResults: function (data, params) {
                var arr = [];
                $.each(data.data, function (index, value) {
                    arr.push({
                        id: value.id_public,
                        text: `${value.responsible_user.name}`
                    });
                })

                return {
                    results: arr
                };
            },
            cache: true
        },
    });

    $.validator.setDefaults({
        submitHandler: function () {
            Swal.fire({
                title: 'Cadastrar o agendamento',
                // text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                if (result.isConfirmed) {
                    let f = $(this.currentForm),
                        a = (f.attr("action") == "") ? window.location.href : f.attr("action");

                    $.ajax({
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        },
                        type: f.attr('method'),
                        url: `${a}`,
                        data: f.serializeArray(),
                        dataType: 'json',
                        beforeSend: function () {
                        },
                        success: function (r) {
                            if (typeof r !== "object") {
                            } else if (r.success) {
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Dados do agendamento',
                                    html: `<b>Solicitante:</b> ${r.data?.nome}<br>
                                           <b>Serviço:</b> ${r.data?.servico}<br>
                                           <b>Instituição:</b> ${r.data?.instituicao}<br>
                                           <b>Data e hora:</b> ${moment(r.data?.dataHora).format("DD/MM/YYYY HH:mm")}`,
                                    didOpen: () => {
                                        Swal.getHtmlContainer().style.textAlign = "left";
                                    },
                                    footer: r.data?.message,
                                }).then((result) => {
                                    if (result.isConfirmed || result.isDismissed) {
                                        limpaTudo();
                                        location.reload();
                                    };
                                });
                            } else {
                                Swal.fire("Error!!!", r.data.join('\n'), "error");
                            }
                        },
                        error: function (r) {
                            Swal.fire("Error!", "Desculpe, encontrei um erro ao agendar, tente novamante mais tarde.", " error ");
                        }
                    });
                }
            })
        }
    });

    $("#agendamento").validate({
        lang: "pt-BR",
        onkeyup: function (element) {
            $(element).valid();
            // console.log($(element).attr('name'));
        }, //
        rules: {
            cpf: {
                required: true,
                minlength: 14,
                maxlength: 14,
                cpfBR: true,
            },
        },
        messages: {
            cpf: {
                required: "Informe seu CPF.",
                minlength: "Continue digitando o CPF",
            },
        },
        errorElement: "span",
        errorPlacement: function (error, element) {
            error.addClass("invalid-feedback");
            element.closest(".form-group").append(error);
        },
        highlight: function (element, errorClass, validClass) {
            $(element).addClass("is-invalid");
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).removeClass("is-invalid");
        },
    });

    function isValidCPF(cpf) {
        if (typeof cpf !== "string") return false;
        cpf = cpf.replace(/[\s.-]*/gim, "");
        if (
            cpf.length !== 11 ||
            !Array.from(cpf).filter((e) => e !== cpf[0]).length
        )
            return false;

        let soma = 0,
            resto;

        for (var i = 1; i <= 9; i++) {
            soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
        }
        resto = (soma * 10) % 11;
        if (resto == 10 || resto == 11) resto = 0;
        if (resto != parseInt(cpf.substring(9, 10))) return false;
        soma = 0;
        for (var i = 1; i <= 10; i++) {
            soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
        }

        resto = (soma * 10) % 11;
        if (resto == 10 || resto == 11) resto = 0;
        if (resto != parseInt(cpf.substring(10, 11))) return false;
        return true;
    }
})(jQuery);
